import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useAudio } from './hooks/useAudio';
import { useLocalStorage } from './hooks/useLocalStorage';
import Home from './components/Home';
import Leaderboard from './components/Leaderboard';
import Awards from './components/Awards';
import Profile from './components/Profile';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import DarkModeToggle from './components/DarkModeToggle';
import './App.css';

function App() {
  const [isPlaying, togglePlay, audio, setVolume] = useAudio('/audio/music.mp3', 0.5, true);
  const [popSoundVolume, setPopSoundVolume] = useLocalStorage('popSoundVolume', 1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [musicVolume, setMusicVolume] = useLocalStorage('musicVolume', 0.5);
  const [hasVisited, setHasVisited] = useState(false);
  const [userPopCount, setUserPopCount] = useState(0);
  const [userName, setUserName] = useState('');
  const [userNickname, setUserNickname] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkMode', false);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const fetchUserData = async () => {
    try {
      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('Session token not found');
      }

      const response = await fetch('https://pineapi.toady.workers.dev/user-data', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Invalid session token, log out the user
          handleLogout();
        } else {
          throw new Error('Error fetching user data: ' + response.statusText);
        }
      } else {
        const userData = await response.json();
        setUserName(userData.username || '');
        setUserNickname(userData.name || '');
        setUserPopCount(userData.popCount || 0);
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      setUserName('');
      setUserNickname('');
      setUserPopCount(0);
    }
  };

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      setIsAuthenticated(true);
      fetchUserData();
    }
  });

  const handleLogin = async () => {
    setIsAuthenticated(true);
    await fetchUserData();
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    window.location.reload();
    Navigate('/login')
  };

  useEffect(() => {
    setVolume(musicVolume);
    const storedHasVisited = localStorage.getItem('hasVisited');
    if (storedHasVisited) {
      setHasVisited(true);
    }
  }, [setVolume, musicVolume, isAuthenticated]);

  const updatePopCount = async (popCount) => {
    try {
      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://pineapi.toady.workers.dev/update-pop-count', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionToken}`,
        },
        body: JSON.stringify({ popCount }),
      });

      if (!response.ok) {
        throw new Error('Error updating pop count: ' + response.statusText);
      }

      const updatedPopCount = await response.json();
      setUserPopCount(updatedPopCount.popCount);
    } catch (error) {
      console.error('Error updating pop count:', error.message);
    }
  };

  const handlePineapplePopped = (popCount) => {
    setUserPopCount((prevCount) => {
      const newCount = prevCount + popCount;
      updatePopCount(popCount);
      return newCount;
    });
  };

  const handleVolumeChange = (event) => {
    const volume = parseFloat(event.target.value);
    setVolume(volume);
    setMusicVolume(volume);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFirstVisit = () => {
    setHasVisited(true);
    localStorage.setItem('hasVisited', true);
  };

  return (
    <Router>
      <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
        <header id="main-header">
          <nav className={isDropdownOpen ? 'active' : ''}>
            <div className="header-container">
              <div className="header-left">
                <button className="menu-toggle" onClick={toggleDropdown}>
                  <i className="fas fa-bars"></i>
                </button>
              </div>
              <ul>
                <li>
                  <Link to="/" onClick={toggleDropdown}>
                    <i className="fas fa-home"></i> Home
                  </Link>
                </li>
                <li>
                  <Link to="/leaderboard" onClick={toggleDropdown}>
                    <i className="fas fa-trophy"></i> Leaderboard
                  </Link>
                </li>
                <li>
                  <Link to="/awards" onClick={toggleDropdown}>
                    <i className="fas fa-medal"></i> Awards
                  </Link>
                </li>
                {!isAuthenticated && (
                  <li>
                    <Link to="/register" onClick={toggleDropdown}>
                      <i className="fas fa-user-plus"></i> Register
                    </Link>
                  </li>
                )}
                {!isAuthenticated && (
                  <li>
                    <Link to="/login" onClick={toggleDropdown}>
                      <i className="fas fa-sign-in-alt"></i> Login
                    </Link>
                  </li>
                )}
                {isAuthenticated && (
                  <li className="profile-link">
                    <Link to="/profile" onClick={toggleDropdown}>
                      <i className="fas fa-user"></i> Profile
                    </Link>
                  </li>
                )}
              </ul>
              <div className="header-right">
                <DarkModeToggle isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </div>
            </div>
          </nav>
        </header>

        <Routes>
          <Route
            exact
            path="/"
            element={
              isAuthenticated ? (
                <Home
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  audio={audio}
                  onVolumeChange={handleVolumeChange}
                  popSoundVolume={popSoundVolume}
                  setPopSoundVolume={setPopSoundVolume}
                  handleFirstVisit={handleFirstVisit}
                  hasVisited={hasVisited}
                  onPineapplePopped={handlePineapplePopped}
                  userNickname={userNickname}
                  popCount={userPopCount}
                  onLogout={handleLogout}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/leaderboard"
            element={
              <Leaderboard
                isPlaying={isPlaying}
                togglePlay={togglePlay}
                audio={audio}
                onVolumeChange={handleVolumeChange}
                popSoundVolume={popSoundVolume}
                setPopSoundVolume={setPopSoundVolume}
                onLogout={handleLogout}
              />
            }
          />
          <Route
            path="/awards"
            element={
              <Awards
                isPlaying={isPlaying}
                togglePlay={togglePlay}
                audio={audio}
                onVolumeChange={handleVolumeChange}
                popSoundVolume={popSoundVolume}
                setPopSoundVolume={setPopSoundVolume}
                popCount={userPopCount}
                onLogout={handleLogout}
              />
            }
          />
          <Route
            path="*"
            element={
              !hasVisited ? (
                <Navigate to="/" replace />
              ) : (
                <div>404 - Page Not Found</div>
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <Profile
                  userName={userName}
                  userNickname={userNickname}
                  popCount={userPopCount}
                  onLogout={handleLogout}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/register"
            element={
              !isAuthenticated ? (
                <RegistrationForm />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/login"
            element={
              !isAuthenticated ? (
                <LoginForm onLogin={handleLogin} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
