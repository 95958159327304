import React from 'react';

function DarkModeToggle({ isDarkMode, setIsDarkMode }) {
  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="dark-mode-toggle">
      <label htmlFor="dark-mode-toggle">
        <input
          type="checkbox"
          id="dark-mode-toggle"
          checked={isDarkMode}
          onChange={handleDarkModeToggle}
        />
        <span className="slider"></span>
      </label>
      <span className="dark-mode-text">Dark Mode</span>
    </div>
  );
}

export default DarkModeToggle;
