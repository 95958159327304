import { useState, useEffect } from 'react';

export const useAudio = (url, initialVolume = 1, loop = false) => {
  const [audio] = useState(new Audio(url));
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(initialVolume);

  useEffect(() => {
    audio.volume = volume;
    audio.loop = loop;
    isPlaying ? audio.play() : audio.pause();
    return () => {
      audio.pause();
    };
  }, [audio, isPlaying, volume, loop]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return [isPlaying, togglePlay, audio, setVolume];
};

export const usePineapplePopSound = () => {
  const playPopSound = () => {
    const storedPopSoundVolume = localStorage.getItem('popSoundVolume');
    let popSoundVolume;

    if (storedPopSoundVolume !== null) {
      popSoundVolume = parseFloat(storedPopSoundVolume);
    } else {
      popSoundVolume = 1;
    }

    const audio = new Audio('/audio/pop.mp3');
    audio.volume = popSoundVolume;
    audio.play();
  };

  return playPopSound;
};
