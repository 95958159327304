import React, { useState } from 'react';

const AwardTooltip = ({ milestone, children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="award-tooltip" onClick={toggleTooltip}>
      {children}
      {isTooltipVisible && <div className="tooltip">{milestone} Pineapples Popped</div>}
    </div>
  );
};

export default AwardTooltip;
