import React from 'react';

function PineappleCounter({ count, randomName }) {
  return (
    <div id="pineapple-counter-box">
      <h2>{randomName}</h2>
      <p id="pineapple-counter">Pineapples Popped: {count}</p>
    </div>
  );
}

export default PineappleCounter;
