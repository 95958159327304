import React from 'react';

function MusicOverlay({ onClick }) {
  return (
    <div id="music-overlay" onClick={onClick}>
      <div className="overlay-content">
        <h2>Click to Start Music</h2>
        <p>Adjust the volume using slider</p>
      </div>
    </div>
  );
}

export default MusicOverlay;
