import React, { useEffect, useState } from 'react';

function VolumeControls({ isPlaying, onTogglePlay, onVolumeChange }) {
  const [musicVolume, setMusicVolume] = useState(null);

  useEffect(() => {
    const storedVolume = localStorage.getItem('musicVolume');
    if (storedVolume) {
      setMusicVolume(parseFloat(storedVolume));
    }
  }, []);

  const handleVolumeChange = (event) => {
    const volume = event.target.value;
    setMusicVolume(volume);
    onVolumeChange(event);
  };

  return (
    <div id="volume-controls-box">
      <div id="volume-controls">
        <i className={`fas fa-${isPlaying ? 'pause' : 'play'}`} onClick={onTogglePlay}></i>
        <i className="fas fa-volume-off volume-icon"></i>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={musicVolume !== null ? musicVolume : 0.5}
          onChange={handleVolumeChange}
        />
        <i className="fas fa-volume-up volume-icon"></i>
      </div>
    </div>
  );
}

export default VolumeControls;
