import React, { useState, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import VolumeControls from './VolumeControls';
import SettingsButtonOverlay from './SettingsButtonOverlay';
import badgeConfig from '../config/badgeConfig';
import AwardTooltip from './AwardTooltip';

function Leaderboard({
  isPlaying,
  togglePlay,
  onVolumeChange,
  popSoundVolume,
  setPopSoundVolume,
  onLogout
}) {
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkMode', false);
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  const fetchLeaderboard = async () => {
    try {
      const response = await fetch('https://pineapi.toady.workers.dev/leaderboard', {
        mode: 'cors'
      });
      if (!response.ok) {
        throw new Error('Error fetching leaderboard: ' + response.statusText);
      }
      const data = await response.json();
      const sortedData = Object.entries(data).sort(([, a], [, b]) => b - a);
      setLeaderboardData(sortedData);
    } catch (error) {
      console.error('Error fetching leaderboard:', error.message);
    }
  };

  const getAwardColor = (milestone) => {
    const badgeData = badgeConfig.find((badge) => badge.milestone === milestone);
    return badgeData ? badgeData.color : '#ccc';
  };

  const getAwardEffect = (milestone) => {
    const badgeData = badgeConfig.find((badge) => badge.milestone === milestone);
    return badgeData ? badgeData.effect : '';
  };

  const renderLatestAward = (popCount) => {
    const unlockedMilestones = badgeConfig.filter((badge) => popCount >= badge.milestone);
    const latestMilestone = unlockedMilestones[unlockedMilestones.length - 1];

    if (latestMilestone) {
      return (
        <AwardTooltip milestone={latestMilestone.milestone}>
          <div
            className={`badge ${getAwardEffect(latestMilestone.milestone)} milestone-${latestMilestone.milestone}`}
            style={{ backgroundColor: getAwardColor(latestMilestone.milestone) }}
          >
            <div className="badge-icon">
              <span className="badge-number">{latestMilestone.milestone}</span>
            </div>
          </div>
        </AwardTooltip>
      );
    }

    return null;
  };

  return (
    <div id="content-wrapper">
      <div id="top-controls">
        <VolumeControls
          isPlaying={isPlaying}
          onTogglePlay={togglePlay}
          onVolumeChange={onVolumeChange}
        />
        <SettingsButtonOverlay
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          popSoundVolume={popSoundVolume}
          setPopSoundVolume={setPopSoundVolume}
          onLogout={onLogout}
        />
      </div>


      <div className="leaderboard-container">
        <h1>Leaderboard</h1>
        <div id="leaderboard">
          {leaderboardData.map(([player, popCount], index) => (
            <div key={player} className="leaderboard-item">
              <span className="rank">{index + 1}</span>
              <span className="player-name">
                {player}
                {renderLatestAward(popCount)}
              </span>
              <span className="pop-count">{popCount}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
