import React, { useState, useEffect, forwardRef } from 'react';

const Countdown = forwardRef((props, ref) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const countdownDate = new Date('March 18, 2024 00:00:00').getTime();
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (distance < 0) {
        clearInterval(intervalId);
        setCountdown('Happy Birthday, Victoria!');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="countdown-box" ref={ref}>
      <div id="countdown">{countdown}</div>
      {countdown !== 'Happy Birthday, Victoria!' && (
        <p className="mystery-text">Something special is coming...</p>
      )}
    </div>
  );
});

export default Countdown;
