import React, { useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import VolumeControls from './VolumeControls';
import SettingsButtonOverlay from './SettingsButtonOverlay';
import badgeConfig from '../config/badgeConfig';
import AwardTooltip from './AwardTooltip';

function Awards({
  isPlaying,
  togglePlay,
  onVolumeChange,
  popSoundVolume,
  setPopSoundVolume,
  popCount,
  onLogout
}) {
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkMode', false);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const getAwardColor = (milestone) => {
    const badgeData = badgeConfig.find((badge) => badge.milestone === milestone);
    return badgeData ? badgeData.color : '#ccc';
  };

  return (
    <div id="content-wrapper">
      <div id="top-controls">
        <VolumeControls
          isPlaying={isPlaying}
          onTogglePlay={togglePlay}
          onVolumeChange={onVolumeChange}
        />
        <SettingsButtonOverlay
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          popSoundVolume={popSoundVolume}
          setPopSoundVolume={setPopSoundVolume}
          onLogout={onLogout}
        />
      </div>

      <div className="awards-container">
        <h1>Awards</h1>
        <div className="awards-grid">
          {badgeConfig.map((badge, index) => (
            <AwardTooltip key={badge.milestone} milestone={badge.milestone}>
              <div className={`award ${popCount >= badge.milestone ? 'unlocked' : ''}`}>
                <div
                  className={`badge ${badge.effect} milestone-${badge.milestone}`}
                  style={{ backgroundColor: getAwardColor(badge.milestone) }}
                >
                  <div className="badge-icon">
                    <span className="badge-number">{badge.milestone}</span>
                  </div>
                  <div className="badge-ribbon"></div>
                </div>
                <p>{badge.milestone} Pineapples Popped</p>
              </div>
            </AwardTooltip>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Awards;
