import React, { useState, useEffect, useRef } from 'react';
import { usePineapplePopSound } from '../hooks/useAudio';

function Pineapples({ onPineapplePopped, popSoundVolume, rainbowPineappleRarity }) {
  const [pineapples, setPineapples] = useState([]);
  const playPopSound = usePineapplePopSound(popSoundVolume);
  const pineapplesRef = useRef(null);

  useEffect(() => {
    const container = pineapplesRef.current;
    const containerWidth = container.clientWidth;

    const createPineapple = () => {
      if (pineapples.length < 20) {
        const isRainbowPineapple = Math.random() < rainbowPineappleRarity;
        const newPineapple = {
          id: Date.now(),
          left: `${Math.random() * (containerWidth - 100)}px`,
          animationDuration: `${Math.random() * 5 + 5}s`,
          isRainbow: isRainbowPineapple,
        };
        setPineapples((prevPineapples) => [...prevPineapples, newPineapple]);
      }
    };

    const spawnInterval = setInterval(() => {
      if (pineapplesRef.current) {
        createPineapple();
      }
    }, 500);

    return () => {
      clearInterval(spawnInterval);
    };
  }, [pineapples.length, rainbowPineappleRarity]);

  const handlePineapplePopped = (id) => {
    const poppedPineapple = pineapples.find((pineapple) => pineapple.id === id);
    const popCount = poppedPineapple.isRainbow ? 10 : 1;
    onPineapplePopped(popCount);
    playPopSound();

    setPineapples((prevPineapples) =>
      prevPineapples.map((pineapple) =>
        pineapple.id === id ? { ...pineapple, popped: true } : pineapple
      )
    );

    setTimeout(() => {
      setPineapples((prevPineapples) =>
        prevPineapples.filter((pineapple) => pineapple.id !== id)
      );
    }, 500);
  };

  return (
    <div className="pineapples" ref={pineapplesRef}>
      {pineapples.map((pineapple) => (
        <div
          key={pineapple.id}
          className={`pineapple ${pineapple.popped ? 'popped' : ''} ${pineapple.isRainbow ? 'rainbow' : ''}`}
          style={{
            left: pineapple.left,
            animationDuration: pineapple.animationDuration,
          }}
          onClick={() => handlePineapplePopped(pineapple.id)}
        ></div>
      ))}
    </div>
  );
}

export default Pineapples;
