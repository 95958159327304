import React, { useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import SettingsButtonOverlay from './SettingsButtonOverlay';

function Profile({
  userName,
  userNickname,
  popCount,
  onLogout
}) {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profilePicture, setProfilePicture] = useLocalStorage('profilePicture', null);
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkMode', false);
  const [popSoundVolume, setPopSoundVolume] = useLocalStorage('popSoundVolume', 1);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match');
      return;
    }

    try {
      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('User not authenticated');
      }

      const formData = new FormData();
      formData.append('currentPassword', currentPassword);
      formData.append('newPassword', newPassword);
      formData.append('profilePicture', profilePicture);

      const response = await fetch('https://pineapi.toady.workers.dev/update-profile', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert('Profile updated successfully');
      } else {
        alert('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  return (
    <div className={`profile-page ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Profile</h2>
      <div className="profile-info">
        <img
          src={profilePicture || '/img/pineapple03.png'}
          alt="Profile"
          className="profile-picture"
        />
        <h3>{userName}</h3>
        <h2>{userNickname}</h2>
        <h2>Pop Count: {popCount}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="currentPassword">Current Password:</label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm New Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="profilePicture">Profile Picture:</label>
          <input
            type="file"
            id="profilePicture"
            accept="image/*"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
        </div>
        <button type="submit">Update Profile</button>
      </form>
      <SettingsButtonOverlay
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        popSoundVolume={popSoundVolume}
        setPopSoundVolume={setPopSoundVolume}
        onLogout={onLogout}
      />
    </div>
  );
}

export default Profile;
