import React, { useState, useEffect, useRef } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import Countdown from './Countdown';
import Pineapples from './Pineapples';
import VolumeControls from './VolumeControls';
import SettingsButtonOverlay from './SettingsButtonOverlay';
import PineappleCounter from './PineappleCounter';
import MusicOverlay from './MusicOverlay';
import Confetti from './Confetti';

function Home({
  isPlaying,
  togglePlay,
  onVolumeChange,
  popSoundVolume,
  setPopSoundVolume,
  handleFirstVisit,
  hasVisited,
  musicVolume,
  onPineapplePopped,
  userNickname,
  popCount,
  onLogout
}) {
  const [isDarkMode, setIsDarkMode] = useLocalStorage('darkMode', false);
  const countdownRef = useRef(null);
  const [shouldFireConfetti, setShouldFireConfetti] = useState(true);

  useEffect(() => {
    setShouldFireConfetti(true);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const handleMusicOverlayClick = () => {
    togglePlay();
    handleFirstVisit();
  };

  return (
    <div id="content-wrapper">
      <Confetti shouldFire={shouldFireConfetti} setShouldFire={setShouldFireConfetti} />
      {!hasVisited && <MusicOverlay onClick={handleMusicOverlayClick} />}

      <div id="top-controls">
        <VolumeControls
          isPlaying={isPlaying}
          onTogglePlay={togglePlay}
          onVolumeChange={onVolumeChange}
          musicVolume={musicVolume}
        />
        <SettingsButtonOverlay
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          popSoundVolume={popSoundVolume}
          setPopSoundVolume={setPopSoundVolume}
          onLogout={onLogout}
        />
      </div>

      <PineappleCounter
        count={popCount}
        randomName={userNickname}
      />

      <Countdown ref={countdownRef} />
      <Pineapples
        onPineapplePopped={onPineapplePopped}
        popSoundVolume={popSoundVolume}
      />
    </div>
  );
}

export default Home;
