import React, { useState } from 'react';

function SettingsButtonOverlay({ popSoundVolume, setPopSoundVolume, onLogout }) {
  const [isSettingsOverlayShown, setIsSettingsOverlayShown] = useState(false);

  const handleSettingsClick = () => {
    setIsSettingsOverlayShown(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOverlayShown(false);
  };

  const handlePopSoundVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setPopSoundVolume(newVolume);
    localStorage.setItem('popSoundVolume', newVolume);
  };

  const handleLogout = () => {
    localStorage.removeItem('sessionToken');
    onLogout();
  };

  return (
    <>
      <button id="settings-button" onClick={handleSettingsClick}>
        <i className="fas fa-cog"></i>
      </button>
      {isSettingsOverlayShown && (
        <div id="settings-overlay">
          <div className="settings-container">
            <div className="settings-header">
              <h2>Settings</h2>
              <button className="close-button" onClick={handleSettingsClose}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            {popSoundVolume !== undefined && (
              <div className="setting">
                <label htmlFor="pop-sound-volume">Pop Sound Volume:</label>
                <input
                  type="range"
                  id="pop-sound-volume"
                  min="0"
                  max="1"
                  step="0.1"
                  value={popSoundVolume}
                  onChange={handlePopSoundVolumeChange}
                />
              </div>
            )}
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SettingsButtonOverlay;
