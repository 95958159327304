const badgeConfig = [
  { milestone: 100, color: '#cd7f32' },
  { milestone: 200, color: '#c0c0c0' },
  { milestone: 500, color: '#ffd700' },
  { milestone: 1000, color: '#ff6b6b' },
  { milestone: 1500, color: '#4ecdc4' },
  { milestone: 2000, color: '#cfff6d', effect: 'shiny' },
  { milestone: 2500, color: '#ff9ff3', effect: 'shiny' },
  { milestone: 3000, color: '#1e88e5', effect: 'shiny' },
  { milestone: 5000, color: '#6a1b9a', effect: 'shiny' },
  { milestone: 10000, color: '#ff6b6b', effect: 'special-shiny' },
  { milestone: 20000, color: '#ff6b6b', effect: 'special-shiny' },
  { milestone: 30000, color: '#4ecdc4', effect: 'special-shiny' },
  { milestone: 50000, color: '#1e1f1a', effect: 'special-shiny' },
];

export default badgeConfig;
