import React, { useEffect, useRef } from 'react';
import ReactConfetti from 'react-confetti';

function Confetti({ shouldFire, setShouldFire }) {
  const confettiRef = useRef(null);

  useEffect(() => {
    if (shouldFire) {
      setTimeout(() => {
        setShouldFire(false);
      }, 10000);
    }
  }, [shouldFire, setShouldFire]);

  return (
    <div ref={confettiRef}>
      <ReactConfetti
        numberOfPieces={200}
        recycle={false}
        run={shouldFire}
        width={window.innerWidth}
        height={window.innerHeight}
        confettiSource={{
          x: window.innerWidth / 2,
          y: 0,
          w: 0,
          h: 0,
        }}
      />
    </div>
  );
}

export default Confetti;
